.wrapper {
  margin: 32px auto;
  max-width: 800px;
  padding: 0 32px;

  display: flex;
  flex-direction: column;

  gap: 16px;

  & > * {
    margin: 0;
    padding: 0;
  }
}