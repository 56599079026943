@import 'breakpoints';

html {
  --isPackageHovering: initial;
  --baseFont: deva-ideal, sans-serif;
  --styledFont: neonoir, sans-serif;
  --logoFont: deva-ideal, sans-serif;

  --brown: #953e2b;
  --green: #2f4f4c;
  --yellow: #d29604;

  --primary: #953e2b;
  --secondary: #e2705e;
  --lightText: #fde6ce;

  --brown: black;
  --green: #333;
  --yellow: black;
  --primary: black;
  --secondary: black;
  --lightText: white;
}

body {
  font-family: var(--baseFont);
  overflow-x: hidden;
  color: black;
  font-size: 16px;

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
  }

  [data-blok-uid] * {
    pointer-events: none;
  }

  h1,
  h2,
  h3,
  p {
    font-weight: 300;
    margin: 0;
    padding: 0;

    margin-top: 32px;
    line-height: 1.4;
  }

  h1 {
    font-size: 40px;
    font-weight: 100;
    text-transform: uppercase;
  }

  h2,
  h3 {
    font-weight: 100;
    font-size: 36px;
    font-family: var(--styledFont);
  }

  h2,
  h3 {
    font-size: 30px;
  }

  h3 {
    font-size: 30px;
  }

  p {
    font-size: 16px;
    font-weight: 300;

    @include mobile {
      font-size: 14px;
      font-weight: 300;
    }
  }

  figure {
    margin: 0;
    padding: 0;
  }

  [data-noMarginTop] {
    margin-top: 0;
  }
}

body [data-blok-uid] * {
  pointer-events: all;
}



body [data-blok-uid] a {
  pointer-events: none;
}

.smartContainer {
  margin: 0 auto;
  padding: 0 16px;
  max-width: 1500px;
  overflow: visible;
}
