.items {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  align-items: flex-start;
  justify-content: flex-start;

  align-content: flex-start;
  justify-items: flex-start;

  // height: calc(100vh - 100px);
  position: relative;
  overflow-x: scroll;

  scroll-snap-align: start;
  scroll-snap-type: x mandatory;
  // scroll-snap-stop: always;
  -webkit-overflow-scrolling: touch;
  gap: 16px;
  // scroll-padding-left: 16px;
  // scroll-padding-right: 16px;
  padding: 0 16px;
  height: calc(700px);
  height: 74vh;
  // width:100%;

  .item {
    display: flex;
    flex: 1 1 30vh;
    // margin: 16px;
    position: relative;
    // display: block;
    // height: 300px;
    width: min(calc(100vw - 60px), 60vh);
    scroll-snap-align: center;
    // scroll-snap-stop: always;

    &:nth-child(10n+3) {
      flex: 1 1 20vh;
    }
    
    &:nth-child(10n+8) {
      flex: 1 1 40vh;
    }

    // &:nth-child(4n+5) {
    //   flex: 1 1 20vh;
    // }

    // &:nth-child(8n+7) {
    //   flex: 1 1 30vh;
    // }

    // &:nth-child(5n+3) {
    //   flex: 1 1 40vh;
    // }

    &:nth-child(5n+1) {
      flex: 1 1 90vh;
    }


    img {
      height: 100%;
      width: 100%;

      object-fit: cover;
      max-height: 100%;
      max-width: 100%;
      position: absolute;
    }
  }
}
