@use 'Styles/breakpoints';

.container {
  margin: 32px auto;
  padding: 48px;
  display: flex;
  background: #f7f7f7;
  overflow-x: hidden;
  max-width: 1400px;
  flex-direction: column;
}

.images {
  display: flex;
  flex-direction: row;
  .img {
    margin: 16px;
    flex: 1 1 200px;

    &:nth-child(n + 5) {
      display: none;
    }

    @include breakpoints.tablet {
      &:nth-child(n + 4) {
        display: none;
      }
    }

    @include breakpoints.mobile {
      &:nth-child(n + 2) {
        display: none;
      }
    }

    img {
      width: 100%;
    }
  }
}

.text {
  margin: 16px 16px 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  text-transform: uppercase;

  > div {

  }
}
