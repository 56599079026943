@use 'Styles/breakpoints';

.container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 32px -16px -16px;
  align-items: stretch;
  justify-content: center;
}

.text {
  position: relative;
  display: flex;
  flex-direction: column;
  order: 4;
  background: black;
  color: white;
  color: black;
  background: white;
  padding: 32px;
  gap: 16px;
  line-height: 1.4;
  font-size: 16px;

  > * {
    padding: 0;
    margin: 0;
  }
}

.itemImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.columns {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 32px;
}

.column {
  flex: 1 1 300px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  text-align: center;

  .item {
    aspect-ratio: 4 / 3;
    position: relative;
    display: none;
    order: 10;
  }

  &:nth-child(1) {
    .item {
      &:nth-child(3n + 1) {
        display: flex;
      }
    }
  }

  &:nth-child(2) {
    .item {
      &:nth-child(3n + 2) {
        display: flex;
      }

      &:nth-child(2) {
        aspect-ratio: 3 / 2;
        order: 1;
      }
    }
  }

  &:nth-child(3) {
    .item {
      &:nth-child(3n + 3) {
        display: flex;
      }
    }
  }

  @include breakpoints.mobile {
    display: none;

    &:nth-child(1) {
      display: flex;

      .item {
        display: flex;
      }
    }

  }
}
