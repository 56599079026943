.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // padding: 32px 0;
  text-align: center;
  // margin: 64px auto;
}

.button {
  display: flex;
  text-align: center;

  font-size: 16px;
  text-transform: uppercase;
  color: inherit;
  text-decoration: none;
  padding: 8px 0px;

  border-bottom: 1px solid black;
}
