.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100px;
  margin-top: 30px;
}

.bar {
  background: var(--primary);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 70px;
}

.semi {
  position: absolute;
  background: var(--secondary);
  width: 200px;
  height: 100px;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: var(--lightText);
  font-family: var(--logoFont);
  font-size: 20px;
  font-weight: 100;
  padding-top: 35px;
  letter-spacing: 3px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
}

.menu {
  position: sticky;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  height: 50px;
  background: white;
  top: 0;
  z-index: 1000;
  border-top: 2px solid var(--brown);

  .menuItem {
    padding: 0 15px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
    color: inherit;
    text-transform: uppercase;
  }
}
