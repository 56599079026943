.container {
  display: flex;
  flex-direction: row;
  margin: -16px -16px -16px;
  flex-wrap: wrap;
}

.section {
  display: flex;
  flex-direction: column;
  margin: 16px;
  flex: 1 1 350px;
  align-items: center;
  justify-content: center;
  position: relative;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.img {
  display: flex;
  height: 600px;
  width: 100%;
  object-fit: cover;
  object-position: center;
  /* filter: brightness(0.8); */
}

.title {
  z-index: 5;
  display: flex;
  text-align: center;
  width: 100%;
  padding: 0 24px;
  margin-bottom: 8px;
  justify-content: center;
}
