@use 'Styles/if';

.container {
  display: flex;
  flex-direction: row;
  margin: 80px 0;
  flex-wrap: wrap;
  gap: 16px;

}

.package {
  --isPackageHovering: initial;
  --hoveringOpacity: 0;

  display: flex;
  flex-direction: column;
  // margin: 16px;
  /* border: 32px solid #eee; */

  flex: 1 1 350px;
  align-items: center;
  justify-content: center;
  position: relative;

  &:nth-child(1) {
    --packageColor: var(--brown);
  }

  &:nth-child(2) {
    --packageColor: var(--yellow);
  }

  &:nth-child(3) {
    --packageColor: var(--green);
  }

  &:hover {
    --isPackageHovering: ;
  }

  h2 {
    // @include if.if(--isPackageHovering, 1, 0, --hoveringOpacity);
    opacity: var(--hoveringOpacity);
    transition: 0.6s;
  }
}

.price {
  // @include if.if(--isPackageHovering, 1, 0, --hoveringOpacity);
  opacity: var(--hoveringOpacity);
  transition: 0.6s;
}

.content {
  display: flex;
  flex-direction: column;
  // border: 2px solid black;
  background: #f7f7f7;

  background: #000;
  color: #fff;

  height: 600px;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 0 32px;
  // margin-top: 8px;
  /* transform: var(--isPackageHovering, translateY(-32px)); */
  transition: 0.4s;
}

.ball {
  display: flex;
  width: 26px;
  height: 26px;
  border-radius: 1000px;
  margin: 32px auto 0;

  background: var(--packageColor);
  color: white;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: 0.4s;
}

.img {
  // @include if.if(--isPackageHovering, 1, 0, --hoveringOpacity);

  z-index: 10;
  position: absolute;
  height: calc(100% + 4px);
  width: calc(100% + 4px);
  object-fit: cover;
  opacity: var(--hoveringOpacity);
  transition: 0.6s;
  pointer-events: none;
}
