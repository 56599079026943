.wrapper {
  width: 100%;
  display: grid;

  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin: 32px 0;
}

.image {
  aspect-ratio: 1/1;
  width:100%;
  object-fit: cover;
  object-position: center;
}
