@import 'Styles/if';

.container {
  --isAlternateLayout: initial;

  margin: 32px auto;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  max-width: 1400px;

  &[data-alternate-layout='true'] {
    --isAlternateLayout: ;

    flex-direction: row-reverse;
  }
}

.richText {
  flex: 0 1 50%;
  padding: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  .text {
    max-width: 400px;
  }
}

.image {
  flex: 0 1 50%;
  padding: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 300px;

  img {
    height: auto;
    width: 100%;
    max-width: 600px;
  }
}
